var games = document.getElementById("games");
if(games) {
    $.holdReady(true)

}


document.addEventListener("DOMContentLoaded", () => {

    if(games) {
        window.app = new Vue({
            el: '#games',
            mounted() {
                $.holdReady(false)
            },
            data: {
                loaded: false,
                allProviders: true,
                games: []
            }
        })
    } else {
        $.holdReady(false)
    }

})

$(document).ready(function () {

    jcf.replaceAll();

    $(window).scroll(function (e) {
        var header = $(".header");
        var windowTop =$(window).scrollTop();
        if(windowTop > 240) {
            header.addClass("header-fixed")
        } else if(windowTop === 0) {
            header.removeClass("header-fixed")
        }
    });


    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesVisibility: true,
        allowTouchMove: false
    });
    var swiper = new Swiper('.slider__main', {
        speed: 800,
        direction: 'vertical',
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        loop: true,
        thumbs: {
            swiper: galleryThumbs
        },
        breakpoints: {

        }
    });
    $(".mob-btn").click(function () {
        $(".header__navigation nav").toggleClass("active");
        $("body").toggleClass("menu-shown");

    });
    $("li.menu-item-has-children").click(function () {
        $(this).find('ul').slideToggle("slow");
    });
    $(".content-toggle-btn").click(function () {
        $(".content-toggle").css('height', '100%');
        $(this).hide();
    });
    $(".games__new").hover(function () {
        $(this).toggleClass('active');

    });


    var swiperGame = new Swiper('.games__slider', {
        slidesPerView: 6,
        spaceBetween: 55,
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 55,
            },
        }
    });



    $("#game-providers").change(function () {
        var that = this;
        var category = $(that).data("category");
        var data = {};
        if(category) {
            data = { action : 'get_post_by_tag', tag: that.value, category: category}
        } else {
            data = { action : 'get_post_by_tag', tag: that.value };
        }

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: data,
            success: function(response){
                console.log(response);
                app.loaded = true;
                app.games = response;
                console.log(that.value);
                if(that.value === "0") {
                    app.allProviders = true
                } else {
                    app.allProviders = false
                }

            }
        });
    })
});
